<template>
    <div class="custom-cursor">
        <div class="cursor-dot-outline"></div>
        <div class="cursor-dot"></div>
    </div>
</template>

<script>
export default{
    name: 'CustomCursor',
    mounted(){
        var cursor = {
            delay: 2,
            _x: 0,
            _y: 0,
            endX: (window.innerWidth / 2),
            endY: (window.innerHeight / 2),
            cursorVisible: true,
            cursorEnlarged: false,
            $dot: document.querySelector('.cursor-dot'),
            $outline: document.querySelector('.cursor-dot-outline'),

            init: function() {
                this.dotSize = this.$dot.offsetWidth;
                this.outlineSize = this.$outline.offsetWidth;

                this.setupEventListeners();
                this.animateDotOutline();
            },

            updateCursor: function(e) {
                var self = this;

                self.cursorVisible = true;
                self.toggleCursorVisibility();

                self.endX = e.pageX;
                self.endY = e.pageY;
                self.$dot.style.top = self.endY + 'px';
                self.$dot.style.left = self.endX + 'px';
            },

            setupEventListeners: function() {
                var self = this;

                document.querySelectorAll('a').forEach(function(el) {
                    el.addEventListener('mouseover', function() {
                        self.cursorEnlarged = true;
                        self.toggleCursorSize();
                    });
                    el.addEventListener('mouseout', function() {
                        self.cursorEnlarged = false;
                        self.toggleCursorSize();
                    });
                });

                document.querySelectorAll('button').forEach(function(el) {
                    el.addEventListener('mouseover', function() {
                        self.cursorEnlarged = true;
                        self.toggleCursorSize();
                    });
                    el.addEventListener('mouseout', function() {
                        self.cursorEnlarged = false;
                        self.toggleCursorSize();
                    });
                });
                document.querySelectorAll('input').forEach(function(el) {
                    el.addEventListener('mouseover', function() {
                        self.cursorEnlarged = true;
                        self.toggleCursorSize();
                    });
                    el.addEventListener('mouseout', function() {
                        self.cursorEnlarged = false;
                        self.toggleCursorSize();
                    });
                });

                document.addEventListener('mousedown', function() {
                    self.cursorEnlarged = true;
                    self.toggleCursorSize();
                });

                document.addEventListener('mouseup', function() {
                    self.cursorEnlarged = false;
                    self.toggleCursorSize();
                });


                document.addEventListener('mousemove', function(e) {
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();

                    self.endX = e.pageX;
                    self.endY = e.pageY;
                    self.$dot.style.top = self.endY + 'px';
                    self.$dot.style.left = self.endX + 'px';
                });

                document.addEventListener('mouseenter', function(e) {
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();
                    self.$dot.style.opacity = 1;
                    self.$outline.style.opacity = 1;
                });

                document.addEventListener('mouseleave', function(e) {
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();
                    self.$dot.style.opacity = 0;
                    self.$outline.style.opacity = 0;
                });
            },

            animateDotOutline: function() {
                var self = this;

                self._x += (self.endX - self._x) / self.delay;
                self._y += (self.endY - self._y) / self.delay;
                self.$outline.style.top = self._y + 'px';
                self.$outline.style.left = self._x + 'px';

                requestAnimationFrame(this.animateDotOutline.bind(self));
            },

            toggleCursorSize: function() {
                var self = this;

                if (self.cursorEnlarged) {
                    self.$dot.style.transform = 'translate(-50%, -50%) scale(0.75)';
                    self.$outline.style.transform = 'translate(-50%, -50%) scale(1.5)';
                } else {
                    self.$dot.style.transform = 'translate(-50%, -50%) scale(1)';
                    self.$outline.style.transform = 'translate(-50%, -50%) scale(1)';
                }
            },

            toggleCursorVisibility: function() {
                var self = this;

                if (self.cursorVisible) {
                    self.$dot.style.opacity = 1;
                    self.$outline.style.opacity = 1;
                } else {
                    self.$dot.style.opacity = 0;
                    self.$outline.style.opacity = 0;
                }
            }
        }

        if (!("ontouchstart" in document.documentElement)){
            cursor.init();
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';

$primary: $gold_sand;
$primary-ultralight: mix(white, $primary, 80%);
$primary-light: mix(white, $primary, 25%);
$primary-dark: mix(black, $primary, 25%);
$primary-ultradark: mix(black, $primary, 80%);
$black: #333333;
$white: #eeeeee;
$max-width: 800px;

.cursor-dot,
.cursor-dot-outline {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    z-index: 1000;
    transition: opacity 0.3s ease-in-out,
                transform 0.3s ease-in-out;
}

.cursor-dot {
    $size: 8px;
    width: $size;
    height: $size;
    background-color: $primary;
}

.cursor-dot-outline {
    $size: 40px;
    width: $size;
    height: $size;
    background-color: rgba($primary-light, 0.5);
}
</style>
