<template>
    <div class="villa-details">
                    <div  class='villa-details__wrapper'>
                        <button @click.prevent="hide" class='villa-details__back-button'>< BACK TO BOOKING ENQUIRY</button>
                        <h3 class="villa-details__title">{{currentVilla.name}}</h3>
                        <div class='villa-details__top'>
                            <div class='villa-details__container-main'>
                                <p class='villa-details__description'>{{currentVilla.description}}</p>
                                <div class="villa-details__buttons">
                                    <button class='villa-details__select-button villa-details__select-button--hide-on-mobile' @click='pick(currentVilla)'>
                                         {{pickedVilla === currentVilla.id ? 'UNSELECT' : 'SELECT'}} {{currentVilla.name}}</button>
                                    <button class='villa-details__select-button villa-details__select-button--hide-on-mobile' @click.prevent="hide">BACK</button>
                                </div>
                            </div>
                            <div class='villa-details__container-img'>
                                <carousel
                                :autoplayTimeout="5000"
                                :paginationEnabled="true"
                                :paginationColor="'#ddd'"
                                :paginationActiveColor="'#D1BEA6'"
                                :perPage='1'
                                :navigationEnabled="true"
                                :navigationNextLabel="''"
                                :navigationPrevLabel="''"
                                >
                                  <slide v-for='(slide, index) in currentVilla.images' :key='index'>
                                    <img :src="slide.url" alt="">
                                  </slide>
                                </carousel>
                            </div>
                        </div>
                        <div class='villa-details__bottom'>
                            <div class='villa-details__container villa-details__container--list'>
                                <p class='villa-details__title'>OVERVIEW</p>
                                <ul class='villa-details__list-wrap'>
                                    <li>Interior</li>
                                    <li> <span>{{currentVilla.interior}} sqm</span></li>
                                    <li> Exterior</li>
                                    <li> <span>{{currentVilla.exterior}} sqm</span></li>
                                </ul>
                                <ul>
                                    <li> <span>{{currentVilla.guests}} guests</span></li>
                                    <li> <span>{{currentVilla.bedrooms}} bedrooms</span></li>
                                    <li> <span>{{currentVilla.guests}} bathrooms</span></li>
                                    <li> <span>{{currentVilla.floors}} floors</span></li>
                                </ul>

                            </div>
                            <div class="villa-details__container">
                                <p  class='villa-details__title'>KEY FEATURES</p>
                                <p class='villa-details__key-features'  >{{currentVilla.key_features.toString() | formatText}}</p>
                            </div>
                        </div>
                           <button class='villa-details__select-button villa-details__select-button--hide-on-desktop' @click='pick(currentVilla)'>
                               {{pickedVilla === currentVilla.id ? 'UNSELECT' : 'SELECT'}} {{currentVilla.name}}</button>
                           <button class='villa-details__select-button villa-details__select-button--hide-on-desktop' @click.prevent="hide">BACK</button>
                    </div>
                </div>
</template>

<script>
export default {
    data(){
        return {
            // isSelected: false,
        }
    },
    props: ['currentVilla', 'hide','pickVillaMethod','pickedVilla'],
    filters: {
        formatText(text){
            return text.replace(/,/g, ', ')
        }
    },
    methods:{
        pick(villa){
            this.pickVillaMethod(villa);
      
        }
    }
}
</script>
<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
@import '@/styles/framework/buttons.scss';
.villa-details{
    width: 100%;
    min-height: 100vh;
    z-index: 900;
    background: $beige;
    display: flex;
    justify-content: center;
    align-items: center;
    @include  breakpoint-max('tablet'){
        overflow-x: hidden;
        flex-direction: column;
        padding: 0 5%;
    }
    &__title{
          @include  breakpoint-max('tablet'){
              margin-bottom: 10px;
        }
    }
    &__wrapper{
        max-width: 1240px;
        margin: 0 auto;
        flex-direction: column;
    }
    &__top{
        display: flex;
        justify-content: space-between;
        @include  breakpoint-max('tablet'){
            flex-direction: column-reverse;
            justify-content: center;
            text-align: center;
        }
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include  breakpoint-max('tablet'){
            flex-direction: column;
        }
    }
    &__container-main{
        width: 50%;
        @include  breakpoint-max('tablet'){
            width: 100%;

        }
    }
    &__container-img{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        width: 50%;
        height: 100%;
            @include  breakpoint-max('tablet'){
             width: 100%;
             margin-bottom: 40px;
        }

    }
    &__back-button{
        color: $black_brown;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-family: "Poppins";
        background: transparent;
        border: none;
        margin-bottom: 24px;
        width: auto;
        text-align: left;
        transition: color .7s;
        align-self: flex-start;
        &:hover{
            color: $brown;
        }
         @include  breakpoint-max('tablet'){
            margin-bottom: 15px;
         }
          @include  breakpoint-max('mobile'){
            margin-bottom: 15px;
            width: 100%;
            text-align: center;
         }
    }
    &__buttons{
        display: flex;
        button{
            &:first-child{
                margin-right: 20px;
            }
        }
    }
    &__title{
        font-size: $large_heading;
        color: $brown;
        font-family: "Finacier";
        font-weight: 500;
        line-height: 5rem;
        padding-bottom: 28px;
              @include  breakpoint-max('xs-tablet'){
                  font-size: $small_heading;
                line-height: 2.6rem;
                padding-bottom: 10px;
                text-align: center;

            }
    }
    &__description{
        font-family: "Poppins";
        color: $text_color;
        font-size: 1.7rem;
        line-height: 2.7rem;
        margin-bottom: 50px;
        margin-right: 50px;
        text-align:left;
        padding: 0 20px 0 0;
        @include  breakpoint-max('xs-tablet'){
            font-size: $mobile_txt;
            line-height: 1.7rem;
            margin-right: 0px;
        }
        @include  breakpoint-max('mobile'){
            font-size: $mobile_txt;
            line-height: 1.7rem;
            margin-right: 0px;
            text-align:center;

        }

    }
    &__select-button{
        @include button;
        margin-bottom: 40px;
        z-index: 10;
        @include  breakpoint-max('xs-tablet'){
            margin: 0 auto 40px;
        }
         &--hide-on-mobile{
                display:block;
            @include  breakpoint-max('tablet'){
            display:none;

             }
         }
        &--hide-on-desktop{
            display:none;
            @include  breakpoint-max('tablet'){
                margin-top: 30px;;
                display:block;
             }

             @include  breakpoint-max('tablet'){
                 margin-bottom: 0px;
             }
        }
    }
    &__key-features{
        @include  breakpoint-max('tablet'){
            text-align: center;
        }
    }
    &__container{
        border: 1px solid #D1BEA6;
        padding: 40px;
        max-height: 250px;
        min-height: 230px ;
        width: 68%;
        display: flex;
        flex-wrap: wrap;
        @include  breakpoint-max('tablet'){
            width: 100%;
            flex-direction: column;
            justify-content: center;
            max-height: initial;
            padding: 20px;

        }
        &--list{
            width: 30%;
             display: flex;

            @include  breakpoint-max('tablet'){
             width: 100%;
             margin-bottom: 40px;
             justify-content: center;
             align-items: center;
         }
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 50%;
            height: 100%;
            @include  breakpoint-max('tablet'){
                &:first-child{
                    margin-top: 10px;
                }
                }
            li{
                width: 100%;
                list-style: none;
                display: flex;
                color: $text_color;

                @include  breakpoint-max('tablet'){
                     font-size: $mobile_txt;
                    line-height: 1.7rem;
                     width: 100%;
                     display: block;
                     text-align: center;
                    }
            }

            span{
                color: $black_brown;
                // margin: 0 2px;
            }
        }
        p{
             font-family: "Poppins";
             font-size: 1.7rem;
             line-height: 2.7rem;
             color: $text_color;
             display: inline;
             @include  breakpoint-max('tablet'){
                font-size: $mobile_txt;
                line-height: 1.7rem;

            }
        }
         p:first-child{
             color: $brown;
             font-size: 1.4rem;
             line-height: 1.7rem;
             font-family: "Poppins";
             width: 100%;
             @include  breakpoint-max('tablet'){
                font-size: $mobile_txt;
                line-height: 1.7rem;
                text-align: center;


            }
        }
    }
    &__list-wrap{
        @include  breakpoint-max('mobile'){
                width: 100%;
                display: flex;
                justify-content: center;
            flex-wrap: wrap;
            flex-direction: row !important;
            width: 100% !important;
            li{
            span{
            display: flex;
            }
             &:nth-child(1n){
                text-align: right;
            }
            &:nth-child(2n){
                text-align: left;
            }
            width: 50% !important;
            }
            }
    }

    .VueCarousel-navigation-next, .VueCarousel-navigation-prev{
  width: 40px;
  height:50px;
  background-repeat: no-repeat;
  background-position:center;
  cursor: none !important;
}
.VueCarousel-navigation-next{
transform: translateY(-50%) translateX(120%);
    background: url('../../assets/img/ARROW-NEXT-SMALL.png');
  background-repeat: no-repeat;
  background-position:center;
     @include tabletSize{
    transform: translateY(-50%) translateX(120%);
    background: none;
  }
}
.VueCarousel-dot-container{
    margin-top: 0 !important;
    margin-bottom: 20px;
}
.VueCarousel-dot{
    cursor: none !important;
}
.VueCarousel-navigation-prev{
    background: url('../../assets/img/ARROW-PREV-SMALL.png');
  background-repeat: no-repeat;
  background-position:center;
transform: translateY(-50%) translateX(-120%);
  @include tabletSize{
transform: translateY(-50%) translateX(120%);
    background: none;

    }
  }
}

</style>
